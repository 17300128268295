@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@mixin limit_number_of_text_line($number_of_text_line) {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $number_of_text_line;
    /* number of lines to show */
    line-clamp: $number_of_text_line;
    -webkit-box-orient: vertical;
}


@layer components {
    .limit-2 {
        @include limit_number_of_text_line(2);
    }

    .limit-3 {
        @include limit_number_of_text_line(3);
    }

}


img {
    @apply block w-full object-cover;
}

body {
    @apply text-black bg-white font-Poppins text-[13.5px];
    overflow: auto !important;
}

.custom-border {
    @extend .border, .border-gray-300 !optional;

    &:before {
        content: "";
        position: absolute;
        background-color: gray;
        width: 2px;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        transition: background-color 0.3s;
    }
}



@mixin comment-tree {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        bottom: 0;
        width: 2px;
        background-color: #ccc; 
        transform: translateX(-50%);
        z-index: -1; 
    }
}

.comment-tree {
    @include comment-tree;
}